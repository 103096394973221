import axios from "axios";

export default class CategoriasService {
  getAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "category/all")
  }
  getDisponibles(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "category/enabled")
  }
  getById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "category/"+id)
  }
  createCategoria(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "category",data)
  }
  deleteCategoria(id){
    const data={
      id
    }
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "category",{data})
  }
  editCategoria(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "category/edit",data)
  }
  getProductosDeCategoriaById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "category/productos/"+id)
  }
  getSubcategoriasDeCategoriaById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "category/subcategorias/"+id)
  }

  getAllCategoriesConAgregables(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "category/agregables")
  }
}
