<template>
  <div class="container-services">
    <div class="container-title-services">
      <p>{{ $t("messages.ourServices").toUpperCase() }}</p>
    </div>
    <div class="container-cards-services">
      <div class="card-service">
        <div class="card-service-image">
          <img src="../assets/imgs/tarot.png" alt="tarot icon" />
        </div>
        <div class="card-service-title">
          <span>{{ $t("messages.tarotTitle") }}</span>
        </div>
        <div class="card-service-body">
          <span
            >{{ $t("messages.tarotMision") }}
          </span>
        </div>
        <div class="card-service-footer">
          <b-button
            variant="primary"
            style="height: 40px"
            @click="goTo('tarot')"
            >{{ $t("messages.knowMore") }}</b-button
          >
        </div>
      </div>
      <div class="card-service">
        <div class="card-service-image">
          <img src="../assets/imgs/meditation.png" alt="" />
        </div>
        <div class="card-service-title">
          <span>{{ $t("messages.meditationTitle") }}</span>
        </div>
        <div class="card-service-body">
          <span
            >{{ $t("messages.meditationMision") }}
          </span>
        </div>
        <div class="card-service-footer">
          <b-button
            variant="primary"
            style="height: 40px"
            @click="goTo('meditacion')"
            >{{ $t("messages.knowMore") }}</b-button
          >
        </div>
      </div>
      <div class="card-service">
        <div class="card-service-image">
          <img src="../assets/imgs/lotusflower.png" alt="" />
        </div>
        <div class="card-service-title">
          <span>{{ $t("messages.registrosTitle") }}</span>
        </div>
        <div class="card-service-body">
          <span
            >{{ $t("messages.registrosMision") }}</span
          >
        </div>
        <div class="card-service-footer">
          <b-button
            variant="primary"
            style="height: 40px"
            @click="goTo('registros')"
            >{{ $t("messages.knowMore") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesContainer",
  methods: {
    goTo(service) {
      this.$router.push("/sesiones/" + service);
    },
  },
};
</script>

<style lang="scss" scoped>
/* servicios */
.container-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.container-title-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.container-title-services p {
  font-size: 1.9rem;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  letter-spacing: 3px;
  color: var(--primary-misty-oracle);
}

.container-cards-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 60px;
  justify-content: space-around;
}

/* card servicios */
.card-service {
  display: flex;
  flex-wrap: wrap;
  min-height: 590px;
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  overflow: hidden;
  /* margin: 20px; */
  -webkit-box-shadow: 1px 1px 11px 4px rgba(0, 0, 0, 0.27);
  box-shadow: 1px 1px 11px 4px rgba(0, 0, 0, 0.27);
  background-color: #ffffff;
}
.card-service-image {
  display: flex;
  flex-wrap: wrap;
  height: 30%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.card-service-image img {
  height: 110px;
  width: auto;
}
.card-service-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 15%;
  width: 100%;
  background-color: #bfd4d3;
}

.card-service-title span {
  font-size: 26px;
  font-weight: 600;
}
.card-service-body {
  display: flex;
  flex-wrap: wrap;
  height: 40%;
  width: 100%;
  padding: 20px;
  font-size: 1.1rem;
}

.card-service-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 15%;
  width: 100%;
}

@media (max-width: 1400px) {
  .card-service {
    width: 320px;
    margin-top: 30px;
  }
  .card-service-body {
    font-size: 1rem;
  }
}

@media (max-width: 470px) {
  .container-title-services p {
    font-size: 25px;
    letter-spacing: 2px;
  }
  .container-cards-services {
    padding: 30px 10px;
  }

  .card-service {
    margin-top: 30px;
  }
}
</style>
