<template>
  <div class="carousel">
    <div
      class="slides"
      :style="{ transform: `translateX(-${currentSlide * 100}%)` }"
    >
      <div
        v-for="(testimonio, index) in testimonios"
        :key="index"
        class="slide"
      >
        <div style="display:flex;width: 100%;justify-content: center;">
          <div class="container-testimonio">
            <p style="width: 100%; display: flex; font-size: 1.3rem">
              <span
                class="persona-nombre"
                style="font-style: oblique; font-weight: 500"
                >{{ testimonio.persona }}</span
              >
              <span class="servicio-nombre"> - </span>
              <span class="servicio-nombre">{{ testimonio.servicio }}</span>
            </p>
            <blockquote>
              <p>
                {{
                  $i18n.locale === "en"
                    ? testimonio.mensajeEn
                    : testimonio.mensajeEs
                }}
              </p>
            </blockquote>
            <h3>{{ testimonio.cliente }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="indicators">
      <span
        v-for="(testimonio, index) in testimonios"
        :key="index + 'x'"
        :class="{ active: index === currentSlide }"
        class="indicator"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testimonios: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      components: [], // Almacena los componentes a mostrar en el carrusel
      currentSlide: 0, // Índice de la diapositiva actual
      intervalId: null, // ID del intervalo para el cambio automático de diapositivas
    };
  },
  mounted() {
    this.startAutoSlide(); // Iniciar cambio automático de diapositivas al montar el componente
  },
  beforeDestroy() {
    this.stopAutoSlide(); // Detener cambio automático de diapositivas antes de destruir el componente
  },
  methods: {
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      }
    },
    nextSlide() {
      if (this.currentSlide < this.testimonios.length - 1) {
        this.currentSlide++;
      }else{
        this.currentSlide = 0;
      }
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    startAutoSlide() {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 15000);
    },
    stopAutoSlide() {
      clearInterval(this.intervalId);
    },
  },
};
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  min-height: 400px;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 1s ease-in-out;
}

.slide {
  flex: 0 0 100%;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.indicator.active {
  background-color: #333;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #f2f2f2;
  border: none;
  cursor: pointer;
}

.next {
  right: 0;
}

.container-testimonio {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 700px;
  padding: 50px;
  justify-content: space-between;
  font-size: 1.2rem;
  /* border: 1px solid #c1c1c1; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.container-testimonio::before {
  content: "";
  background-image: url("../../assets/imgs/lotus2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 600px) {
  .container-testimonio {
    width: 100%;
    padding: 10px;
  }
  .container-services {
    padding: 20px;
  }
}
</style>
