<template>
  <div style="width: 100%">
    <jumbo-container />
    <!-- <categories-container /> -->

    <!-- add anchor -->
    <a id="services"></a>
    <services-container />
    <benefit-container />
    
    <!-- <audio src="../../assets/sounds/musicMeditation.mp3" autoplay muted>
      Your browser does not support the
      <code>audio</code> element.
    </audio> -->
    <testimonios-container />
    <newsletter-container />
    <seccion-de-articulos/>
  </div>
</template>

<script>
import JumboContainer from "@/components/JumboContainer";
import CategoriesContainer from "@/components/CategoriesContainer";
import ServicesContainer from "@/components/ServicesContainer";
import BenefitsContainer from "@/components/BenefitsContainer";
import NewsletterContainer from "@/components/NewsletterContainer";
import TestimoniosContainer from "@/components/TestimoniosContainer";
import SeccionDeArticulos from "@/components/SeccionDeArticulos";

export default {
  name: "Home",
  components: {
    "jumbo-container": JumboContainer,
    "categories-container": CategoriesContainer,
    "services-container": ServicesContainer,
    "benefit-container": BenefitsContainer,
    "newsletter-container": NewsletterContainer,
    "testimonios-container": TestimoniosContainer,
    "seccion-de-articulos": SeccionDeArticulos,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
