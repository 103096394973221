<template>
  <div class="container-section">
    <div class="container-title-section">
      <p>{{ $t("messages.benefits").toUpperCase() }}</p>
    </div>
    <div class="container-information">
      <p
        v-for="(benefit, index) in benefits"
        :key="index"
        class="label-benefits"
      >
        <b-icon icon="flower2"></b-icon> {{ benefit }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BenefitsContainer",
  data() {
    return {
      benefits: [],
    };
  },
  mounted() {
    this.getBenefits();
  },
  methods: {
    getBenefits() {
      const locale = this.$i18n.locale;
      const messagesBenefits = require(`@/assets/data/benefits.json`);
      this.benefits = messagesBenefits[locale] ?? messagesBenefits.en;
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getBenefits();
    },
  },
};
</script>

<style lang="scss" scoped>
/* servicios */
.container-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--background-misty-oracle);
  padding-top: 60px;
  padding-bottom: 60px;
}
.container-title-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.container-title-section p {
  font-size: 1.9rem;
  margin: 0px;
  margin-bottom: 50px;
  padding: 0px;
  font-weight: bold;
  letter-spacing: 3px;
  color: var(--primary-misty-oracle);
}

.container-information {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 60px;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.label-benefits {
  font-size: 1.3rem;
  margin: 20px 0px;
  padding: 0px 84px;
  letter-spacing: 3px;
  text-align: left;
  list-style-type: square;
}

@media (max-width: 1150px) {
  .container-information {
    padding: 20px 20px;
    grid-template-columns: 1fr;
  }

  .label-benefits {
    font-size: 1.1rem;
    margin: 10px 0px;
    padding: 0px 20px;
    letter-spacing: 2px;
  }
}

@media (max-width: 470px) {
  .container-title-section p {
    font-size: 25px;
    letter-spacing: 2px;
  }
  .container-information {
    padding: 30px 10px;
  }
}
</style>
