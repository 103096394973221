<template>
  <div class="card-propia-container m-2">
    <div class="card-propia-image">
      <img
        :src="`${urlImagesProductos}${producto.imagen}`"
        :alt="producto.nombre"
        @click="mostrarDetalle(producto.id)"
        style="cursor:pointer"
      />
    </div>
    <div class="card-propia-title">
      <span>{{ producto.nombre | todasLasPrimerasMayusculas }}</span>
    </div>
    <div class="card-propia-body">
      <span>{{ producto.descripcion }}</span>
    </div>
    <div class="card-propia-presentaciones">
      <div
        :class="{
          'container-presentacion': true,
          'container-presentacion-seleted': presentacionSelected
            ? presentacionSelected.id == presentacion.id
            : false,
        }"
        @click="selectPresentacion(presentacion)"
        v-for="(presentacion, index) in producto.presentaciones"
        :key="index"
      >
        <span>{{ presentacion.presentacion }}</span>
        <span class="precio-value"
          >{{
            presentacion.precio | numero
          }}
          $</span
        >
      </div>
    </div>
    <div class="card-propia-footer">
      <b-button
        variant="primary"
        style="
          width: 90px;
          font-size: 15px;
          height: 27px;
          padding-top: 0px;
          padding-bottom: 0px;
        "
        @click="addToCar"
        >Agregar</b-button
      >
      <b-form-spinbutton
        id="sb-inline"
        v-model="cantidad"
        inline
      ></b-form-spinbutton>
    </div>
  </div>
</template>

<script>
import EventBus from "@/services/eventBus.js";
export default {
  name: "CardProductHome",
  props: { producto: Object },
  data() {
    return {
      urlImagesProductos: process.env.VUE_APP_API_IMAGES_PRODUCTOS,
      cantidad: 1,
      presentacionSelected: null,
    };
  },
  methods: {
    addToCar() {
      if (!this.presentacionSelected) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debe seleciconar una presentación",
        });
        return false;
      }
      if (this.cantidad<1) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "La cantidad debe ser mayor a 1",
        });
        return false;
      }
      EventBus.$emit("SHOW_MODAL_CAR_SHOP", {
        titleModal: "Agregar al carrito",
        producto:this.producto,
        presentacion:this.presentacionSelected,
        cantidad:this.cantidad        
      });      
    },
    selectPresentacion(item) {
      this.presentacionSelected = { ...item };
    },
    mostrarDetalle(id){
      EventBus.$emit("SHOW_MODAL_DETAIL", {
        idProductoInDetail: id,
        showModalDetail: true,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
/* card */
.card-propia-container {
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  width: 270px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 11px 4px rgba(0, 0, 0, 0.27);
  box-shadow: 1px 1px 11px 4px rgba(0, 0, 0, 0.27);
}
.card-propia-image {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40%;
  overflow: hidden;
  align-content: center;
}

.card-propia-image img {
  width: 100%;
  height: auto;
}
.card-propia-title {
  display: flex;
  flex-wrap: wrap;
  height: 15%;
  width: 100%;
  padding: 5px;
  justify-content: center;
  align-items: center;
}
.card-propia-title span {
  font-size: 20px;
  font-weight: 600;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.card-propia-body {
  display: flex;
  flex-wrap: wrap;
  height: 22%;
  width: 100%;
  padding: 0px 10px;
  justify-content: center;
}
.card-propia-body span {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.card-propia-presentaciones {
  display: flex;
  flex-wrap: wrap;
  height: 13%;
  width: 100%;
  padding: 0px 10px;
  justify-content: center;
  cursor: pointer;
}

.container-presentacion {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #c9719d;
  border-radius: 7px;
  padding: 3px;
  margin: 2px;
  font-size: 11px;
  font-weight: 700;
  height: fit-content;
  width: fit-content;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  transition: 0.4s all;
}

.container-presentacion:hover {
  border: 1px solid gray;
  background-color: #c9719d;
  color: white;
}

.container-presentacion-seleted {
  border: 1px solid gray;
  background-color: #c9719d;
  color: white;
}

.card-propia-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: 10%;
  width: 100%;
}
.precio-value {
  font-size: 16px;
}
</style>
