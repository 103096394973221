<template>
  <div class="container-services">
    <CarrouselPropio :testimonios="testimonios" />
  </div>
</template>

<script>
import CarrouselPropio from './PublicComponents/CarrouselPropio.vue';
export default {
    name: "Testimonios",
    components: { CarrouselPropio },
    data() {
        return {
            testimonios: [],
        };
    },
    mounted() {
        this.testimonios = require(`@/assets/data/testimonios.json`);
    },
};
</script>

<style scoped>
.container-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .container-services {
    padding: 20px;
  }
}
</style>
