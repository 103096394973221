<template>
  <div class="container-services">
    <div class="container-title-services">
      <p class="text-center text-white">NEWSLETTER</p>
    </div>
    <div class="container-left">
      <p style="width: 100%">
        {{ $t("messages.suscribeToOurNewsletter") }}
      </p>
    </div>
    <div class="container-right">
      <form ref="form" @submit.stop.prevent="handleSubmit" style="width: 100%">
        <b-row class="my-1">
          <b-col sm="12" style="min-height: 80px">
            <b-form-input
              id="email-input-newsletter"
              type="email"
              v-model="email"
              ref="email"
              :state="emailState"
              required
              :placeholder="$t('messages.enterYourEmail')"
              style="width: 100%"
              size="lg"
            ></b-form-input>
            <b-form-invalid-feedback
              id="email-input-feedback"
              style="color: #ffffff"
            >
              {{ $t("messages.emailRequired") }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-col sm="12" class="mt-1">
          <b-button variant="secondary" size="lg" @click="addUser">{{
            $t("messages.suscribirme")
          }}</b-button>
        </b-col>
      </form>
    </div>
  </div>
</template>

<script>
import NewsletterService from "@/services/newsletter.service.js";
import EventBus from "@/services/eventBus.js";
export default {
  name: "NewsletterContainer",
  data() {
    return {
      emailState: null,
      email: "",
      newsletterService: null,
    };
  },
  created() {
    this.newsletterService = new NewsletterService();
  },
  methods: {
    addUser() {
      const valides = this.checkFormValidity();
      if (!valides) return;
      try {
        this.newsletterService.suscribe({
          email: this.email,
          idioma: this.$i18n.locale === "es" ? "spanish" : "english",
        });
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "",
          variant: "success",
          message: this.$t("messages.newsletterSuscriptionSuccess"),
        });
        this.email = "";
      } catch (error) {
        console.log(error);
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "danger",
          message: this.$t("messages.errorSuscribingToNewsletter"),
        });
      }
    },
    checkFormValidity() {
      if (Object.keys(this.$refs).length === 0) return false;
      const validEmail = this.$refs.email.checkValidity();
      this.emailState = validEmail;
      return validEmail;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 80px 200px 100px 200px;
  background-color: var(--primary-misty-oracle);
}

.container-title-services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.container-title-services p {
  font-size: 1.9rem;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  letter-spacing: 3px;
}
.container-left {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  height: 100%;
  padding: 10px 30px;
  p {
    color: #ffffff;
    font-size: 1.5rem;
    text-align: end;
  }
}
.container-right {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  height: 100%;
  padding: 0px 30px;
}

@media (max-width: 1400px) {
  .container-services {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 80px 0px 100px 0px;
    background-color: var(--primary-misty-oracle);
  }
}

@media (max-width: 900px) {
  .container-services {
    padding: 100px 0px;
  }

  .container-left {
    width: 100%;
    padding: 0px 30px;
    p {
      font-size: 20px;
      text-align: center;
    }
  }
  .container-right {
    width: 100%;
    padding: 0px 30px;
    margin-top: 40px;
  }
}
</style>
