<template>
  <div class="container-categories">
    <div class="header-categories">
      <div class="container-menu">
        <ul>
          <li
            :class="{
              'item-menu-categories': true,
              'categoria-seleted': categoriaSelected
                ? categoriaSelected.id == categoria.id
                : false,
            }"
            v-for="(categoria, index) in categorias"
            :key="index"
            @click="buscarProductos(categoria)"
          >
            {{ categoria.nombre | todasLasPrimerasMayusculas }}
          </li>
        </ul>
      </div>
      <div class="searcher-products">
        <b-input-group size="sm" class="mb-2">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            placeholder="Busca un producto"
            v-model.trim="cadenaBusqueda"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <div class="container-galery-products">
      <producto-car-home
        v-for="(producto, index) in productosFiltered"
        :key="index"
        :producto="producto"
      />
    </div>
  </div>
</template>

<script>
import CategoriasService from "@/services/categorias.service";
import ProductoCardHome from "@/components/cardProductoHome";
export default {
  name: "CategoriesContainer",
  components: { "producto-car-home": ProductoCardHome },
  data() {
    return {
      categoriasService: null,
      categorias: [],
      productos: [],
      productosFiltered: [],
      cadenaBusqueda: "",
    };
  },
  created() {
    this.categoriasService = new CategoriasService();
  },
  mounted() {
    this.recuperarCategorias();
  },
  methods: {
    recuperarCategorias() {
      this.categoriasService
        .getDisponibles()
        .then((result) => {
          if (result.data.status == 200) {
            this.categorias = [...result.data.data];
            if (this.categorias.length > 0) {
              this.categoriaSelected = { ...this.categorias[0] };
              this.buscarProductos(this.categoriaSelected);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    buscarProductos(categoria) {
      this.categoriaSelected = { ...categoria };
      this.categoriasService
        .getProductosDeCategoriaById(categoria.id)
        .then((result) => {
          this.productos = [];
          if (result.data.status == 200) {
            this.productos = [...result.data.data];
            this.productosFiltered = [...this.productos];
            if (this.cadenaBusqueda.length >= 3) this.filtrarProductos();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    filtrarProductos() {
      if (this.cadenaBusqueda.length == 0) {
        this.productosFiltered = [...this.productos];
      } else {
        this.productosFiltered = this.productos.filter((item) => {
          return (
            item.nombre
              .toLowerCase()
              .includes(this.cadenaBusqueda.toLowerCase()) ||
            item.descripcion
              .toLowerCase()
              .includes(this.cadenaBusqueda.toLowerCase())
          );
        });
      }
    },
  },
  watch: {
    cadenaBusqueda(nuevo) {
      if (nuevo.length >= 3 || nuevo.length == 0) {
        this.filtrarProductos();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* categorias */
.container-categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.header-categories {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.container-menu {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  width: 70%;
  min-height: 60px;
}
.container-menu ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
  align-items: center;
}
.searcher-products {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  width: 30%;
  min-height: 60px;
  padding: 15px 60px;
}
.item-menu-categories {
  display: flex;
  font-weight: 700;
  list-style: none;
  margin: 10px 10px;
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
  transition: 0.5s all;
  background-color: #ffffff;
  border: 2px solid #c9719d;
  color: #4e4e4e;
  letter-spacing: 2px;
  font-size: 15px;
  padding: 10px 20px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.item-menu-categories:hover {
  background-color: #c9719d;
  border: 2px solid gray;
  color: white;
}
.categoria-seleted {
  background-color: #c9719d;
  border: 2px solid gray;
  color: white;
}
.container-galery-products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  background-color: #bfd4d3;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 1430px) {
  .container-menu {
    width: 60%;
  }
  .searcher-products {
    width: 40%;
  }
}
@media (max-width: 1290px) {
  .container-menu {
    width: 100%;
  }
  .searcher-products {
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
  }
}
@media (max-width: 470px) {
  .searcher-products {
    padding: 15px 10px;
  }
  .item-menu-categories {
    padding: 6px;
    font-size: 14px;
    margin: 5px;
  }
  .container-menu ul {
    justify-content: center;
  }
}
</style>
