<template>
  <b-jumbotron
    style="
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      min-height: 650px;
      border-bottom: 4px solid var(--primary-misty-oracle);
    "
  >
    <div class="container-left-jumbo">
      <!-- <transition name="fade"
        ><p v-if="messageActual !== ''">
          {{ messageActual }}
        </p></transition
      > -->
      <h1>{{$t('messages.jumboLine1') }} <br/>{{$t('messages.jumboLine2') }}</h1>
      <hr />
      <p
        v-for="(message, index) in mensagesList"
        :key="index"
      >
        {{ message }}
      </p>
    </div>
    <div class="container-right-jumbo"></div>
  </b-jumbotron>
</template>

<script>
export default {
  name: "JumboContainer",
  data() {
    return {
      mensagesList: [],
      messageActual: "Misty Oracle",
      locale: this.$i18n.locale,
      intervalMessage: null,
      indexMessage: 0,
    };
  },
  mounted() {
    const messagesJumbotron = require(`@/assets/data/messagesJumbotron.json`);
    this.mensagesList = messagesJumbotron[this.locale] ?? messagesJumbotron.es;
    this.intervalMessage = setInterval(() => {
      this.messageActual = "";
      setTimeout(() => {
        if (this.mensagesList[this.indexMessage]) {
          this.messageActual = this.mensagesList[this.indexMessage];
          this.indexMessage++;
        } else {
          this.indexMessage = 0;
          this.messageActual = this.mensagesList[this.indexMessage];
          this.indexMessage++;
        }
      }, 1000);
    }, 7000);
  },
  watch: {
    "$i18n.locale"() {
      this.locale = this.$i18n.locale;
      const messagesJumbotron = require(`@/assets/data/messagesJumbotron.json`);
      this.mensagesList =
        messagesJumbotron[this.locale] ?? messagesJumbotron.es;
      clearInterval(this.intervalMessage);
      this.intervalMessage = setInterval(() => {
        this.messageActual = "";
        setTimeout(() => {
          if (this.mensagesList[this.indexMessage]) {
            this.messageActual = this.mensagesList[this.indexMessage];
            this.indexMessage++;
          } else {
            this.indexMessage = 0;
            this.messageActual = this.mensagesList[this.indexMessage];
            this.indexMessage++;
          }
        }, 1000);
      }, 7000);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-left-jumbo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 50%;
  height: 100%;
  height: inherit;
  background-color: #091b3a;
  padding: 60px;

  h1 {
    color: #ffffff;
    font-size: 2.2vw;
  }
  hr {
    width: 100%;
    border: 1px solid #ffffffcf;
  }
  p {
    color: aliceblue;
    transition: all 0.5s;
    font-size: 1.2vw;
  }
}

.container-right-jumbo {
  width: 50%;
  height: inherit;
  background-image: url("../assets/imgs/imagen_jumbo.png");
  background-size: cover;
  background-position-y: center;
  background-position-x: right;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 1300px) {
  .container-left-jumbo {
    h1 {
      font-size: 3vw;
    }
    p {
      font-size: 2vw;
    }
  }
}

@media (max-width: 800px) {
  .container-left-jumbo {
    width: 100%;
    padding: 10px;
    h1 {
      padding: 0px;
      margin: 0px;
      font-size: 5vw;
    }
    p {
      padding: 0px;
      margin: 0px;
      margin-bottom: 20px;
      font-size: 3vw;
    }
  }

  .container-right-jumbo {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 500px) {
  .container-left-jumbo {
    h1 {
      font-size: 6vw;
    }
    p {
      font-size: 4vw;
    }
  }
}
</style>
